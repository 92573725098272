import PropTypes from "prop-types";
import { createContext, useState } from "react";
// hooks

// ----------------------------------------------------------------------

const initialState = {
  collapseClick: false,
  onToggleCollapse: () => {},
};

const CollapseDrawerContext = createContext(initialState);

// ----------------------------------------------------------------------

CollapseDrawerProvider.propTypes = {
  children: PropTypes.node,
};

function CollapseDrawerProvider({ children }) {
  const [collapse, setCollapse] = useState(false);

  const handleToggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapse: collapse,
        collapseClick: collapse,
        onToggleCollapse: handleToggleCollapse,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}

export { CollapseDrawerProvider, CollapseDrawerContext };
