
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_PAYMENT = "/payment";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
};

export const PATH_PAYMENT = {
  root: ROOTS_PAYMENT,
  activate: path(ROOTS_AUTH, "/activate/:id"),
};

export const PATH_ERROR = {
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  customer: {
    root: path('/', "customers"),
    customer: path('/', "customers/account/:id"),
  },
  cashflow: {
    root: path('/', "cashflow"),
  },
  users: {
    root: path('/', "users"),
  },
  pdf: {
    root: path('/', "pdf"),
  },
  partners: {
    root: path('/', 'partners'),
    partners: path('/', 'partners/account/:id')
  },
};
