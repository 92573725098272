import React from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { nestedNameFieldValue } from "../../../payment/Activate/utils";

const FormikField = ({
  name,
  label = "",
  placeholder = "",
  type = "text",
  className = "",
  labelColor,
  onChange,
}) => {
  const { handleChange, handleBlur, values } = useFormikContext();

  return (
    <div className={`tw-mb-4 ${className}`}>
      <label
        className={`tw-block tw-mb-2 tw-text-base tw-font-semibold ${labelColor}`}
      >
        {label}
      </label>

      <Field
        type={type}
        name={name}
        value={nestedNameFieldValue(name, values) || ""}
        onChange={onChange || handleChange}
        onBlur={handleBlur}
        className="tw-bg-white tw-border tw-border-gray-300 tw-text-swipenGrey-600 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2 tw-mb-1"
        placeholder={placeholder}
      />
      <div className="tw-text-red-600 tw-font-[medium] tw-text-xs">
        <ErrorMessage  name={name} />
      </div>
      
    </div>
  );
};

export default FormikField;
