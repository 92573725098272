import React from "react";
import logo from "../../../../assets/images/swipen-logo-white-crm.svg";

const ConsumerDiscolusure = () => {
  return (
    <div className="tw-text-center tw-max-w-[1400px] xl:tw-mx-auto tw-mx-8">
        <div className="tw-w-full tw-flex tw-justify-center">
             <img
          className="tw-h-16 tw-bg-swipenBlue-500 tw-mt-4 tw-rounded-lg lg:tw-hidden tw-p-2"
          src={logo}
          alt=""
        />
        </div>
       
      <div className="tw-text-sm tw-h-20 tw-relative tw-items-center tw-justify-center ph:tw-justify-between tw-mt-4 tw-flex tw-bg-swipenBlue-500 tw-text-white tw-text-swipenBlue-500 tw-mb-1">
        <div className="tw-w-0 tw-h-0 tw-hidden ph:tw-block tw-border-[40px] tw-border-solid tw-border-transparent tw-border-l-white"></div>
        <img
          className="tw-h-16 tw-hidden lg:tw-block tw-absolute tw-left-12 tw-p-1"
          src={logo}
          alt=""
        />
        <h1 className="tw-text-4xl tw-text-center tw-text-white">
          Consumer Disclosure
        </h1>

        <div className="tw-w-0 tw-h-0 tw-hidden ph:tw-block tw-border-[40px] tw-border-solid tw-border-transparent tw-border-r-white"></div>
      </div>

      <h1 className="tw-text-swipenBlue-500 tw-text-xl tw-mt-6 tw-m-4">
        {" "}
        Consumer Disclosure Regarding Conducting Business Electronically,
        Receiving Electronic Notices and Disclosures, and Signing Documents
        Electronically
      </h1>
      <p>
        Please read the following information, by proceeding forward and signing
        this document you are agreeing that you have reviewed the following
        consumer disclosure information and consent to transact business using
        electronic communications, to receive notices and disclosures
        electronically, and to utilise electronic signatures in lieu of using
        paper documents. This electronic signature service is provided on behalf
        of Swipen, who are sending electronic documents, notices, disclosures or
        requesting electronic signatures to you.
      </p>
      <p>
        You are not required to receive notices and disclosures or sign
        documents electronically. If you prefer not to do so, you may request to
        receive paper copies and withdraw your consent at any time as described
        below.
      </p>
      <h1 className="tw-text-swipenBlue-500 tw-text-xl tw-mt-6 tw-m-4">
        Paper Copies
      </h1>
      <p>
        You are not required to receive notices or disclosures or sign documents
        electronically and may request paper copies of documents or disclosures
        if you prefer to do so. You also have the ability to download and print
        any open or signed documents sent to you through the electronic
        signature service using the PDF and Print icons. We may also email you a
        PDF copy of all agreements you sign using the electronic signature
        service. If you wish to receive paper copies in lieu of electronic
        documents you may close this web browser and request paper copies from
        Swipen by following the procedures outlined below. Swipen may apply a
        charge for requesting paper copies.
      </p>

      <h1 className="tw-text-swipenBlue-500 tw-text-xl tw-mt-6 tw-m-4">
        Withdrawal of Consent
      </h1>
      <p>
        You may withdraw your consent to receive electronic documents, notices
        or disclosures at any time. In order to withdraw consent you must notify
        Swipen that you wish to withdraw consent and to provide your future
        documents, notices, and disclosures in paper format. After withdrawing
        consent if at any point in the future you proceed forward and utilise
        the electronic signature system you are once again consenting to receive
        notices, disclosures, or documents electronically. You may withdraw
        consent to receive electronic notices and disclosures and optionally
        electronic signatures by following the procedures described below.
      </p>

      <h1 className="tw-text-swipenBlue-500 tw-text-xl tw-mt-6 tw-m-4">
        Scope of Consent
      </h1>
      <p>
        You agree to receive electronic notices, disclosures, and electronic
        signature documents with all related and identified documents and
        disclosures provided over the course of your relationship with Swipen.
        You may at any point withdraw your consent by following the procedures
        described below.
      </p>
      <h1 className="tw-text-swipenBlue-500 tw-text-xl tw-mt-6 tw-m-4">
        Requesting paper copies, withdrawing consent, and updating contact
        information
      </h1>
      <p>
        You have the ability to download and print any documents we send to you
        through the electronic signature system. To request paper copies of
        documents, withdraw consent to conduct business electronically and
        receive documents, notices, or disclosures electronically or sign
        documents electronically please contact Swipen by email with the
        following subjects:
      </p>

      <p className="tw-mt-6">
        {" "}
        <span className="tw-text-swipenBlue-500 tw-text-xl tw-font-[bold]">
          {" "}
          “Requesting Paper Copies”
        </span>{" "}
        Please provide your name, email, telephone number, postal address and
        document title.
      </p>

      <p className="tw-mt-6">
        <span className="tw-text-swipenBlue-500 tw-text-xl tw-font-[bold]">
          “Withdraw Consent”
        </span>{" "}
        Please provide your name, email, date, telephone number, postal address.
      </p>
      <p className="tw-mt-6">
        <span className="tw-text-swipenBlue-500 tw-text-xl tw-font-[bold]">
          “Update Contact Information”
        </span>{" "}
        Please provide your name, email, telephone number and postal address.
      </p>

      <p className="tw-my-6">
        Any fees associated with sending paper copies or withdrawing consent
        will be determined by the Swipen.
      </p>
    </div>
  );
};

export default ConsumerDiscolusure;
