import { Outlet } from "react-router-dom";

// ----------------------------------------------------------------------

export default function MainLayout() {
  return (
    <div className="tw-min-h-screen tw-flex tw-flex-col sm:tw-justify-center tw-items-center tw-pt-6 sm:tw-pt-0  tw-bg-swipenBlue-500">
      <Outlet />
    </div>
  );
}
