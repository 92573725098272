import axios from "axios";
import { APPLICATION_SERVICE_HOST_API } from "../config";



// Application Services axios connection
const applicationServiceAxios = axios.create({
    baseURL: APPLICATION_SERVICE_HOST_API,
  });
  
  applicationServiceAxios.interceptors.response.use(
    (response) => {
      if(response?.data?.status === 'Token is Expired'){
        window.localStorage.removeItem("applicationToken");
        window.location.reload();
      }
      return response;
    },
    (error) => {
      console.log("in the error: ", error);
      return Promise.reject(
        (error.response && error.response.data) || "Something went wrong"
      );
    }
  );

export default applicationServiceAxios;