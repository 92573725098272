import React from 'react'
import FieldRow from './FieldRow';

const ProposalFeilds = ({ ratesAndProducts, secondaryColor, title, subtitle, ratesKey }) => {
 
    const fieldList = [
        { title: "Visa Debit Personal", bg: "blue-50", key: "visa_debit_personal" },
        { title: "Mastercard Debit Personal", bg: null, key: "mastercard_debit_personal" },
        { title: "Visa Credit Personal", bg: "blue-50", key: "visa_credit_personal" },
        { title: "Mastercard Credit Personal", bg: null, key: "mastercard_credit_personal" },
        { title: "Visa Premium", bg: "blue-50", key: "visa_premium" },
        { title: "Mastercard Premium", bg: null, key: "mastercard_premium" },
        { title: "Visa Commercial Debit", bg: "blue-50", key: "visa_commercial_debit" },
        { title: "Visa Commercial Credit", bg: null, key: "visa_commercial_credit" },
        { title: "Mastercard Commercial Debit", bg: "blue-50", key: "mastercard_commercial_debit" },
        { title: "Mastercard Commercial Credit", bg: null, key: "mastercard_commercial_credit" },
        { title: "Visa Debit European", bg: "blue-50", key: "visa_debit_european" },
        { title: "Mastercard Debit European", bg: null, key: "mastercard_debit_european" },
        { title: "Visa Credit European", bg: "blue-50", key: "visa_credit_european" },
        { title: "Mastercard Credit European", bg: null, key: "mastercard_credit_european" },
        { title: "Visa Commercial Debit European", bg: "blue-50", key: "visa_commercial_debit_european" },
        { title: "Mastercard Commercial Debit European", bg: null, key: "mastercard_commercial_debit_european" },
        { title: "Visa Commercial Credit European", bg: "blue-50", key: "visa_commercial_credit_european" },
        { title: "Mastercard Commercial Credit European", bg: null, key: "mastercard_commercial_credit_european" },
        { title: "Standard Credit International", bg: "blue-50", key: "standard_credit_international" },
        { title: "Standard Debit International", bg: null, key: "standard_debit_international" },
        { title: "Non-standard Credit International", bg: "blue-50", key: "nonstandard_credit_international" },
        { title: "Non-standard Debit International", bg: null, key: "nonstandard_debit_international" },
        { title: "Consumer Debit MOTO", bg: "blue-50", key: "consumer_debit_moto" },
        { title: "Consumer Credit MOTO", bg: null, key: "consumer_credit_moto" },
        { title: "Non-qualified MOTO", bg: "blue-50", key: "nonqualified_moto" },
      ];
    
      return (
        <div style={{borderColor: secondaryColor}} className="tw-border-2 dropDown tw-h-auto lg:tw-text-base tw-text-xs ph:tw-text-sm tw-w-[98%] sm:tw-w-11/12 tw-mx-auto tw-mt-6 tw-rounded-lg ">
          <div style={{backgroundColor: secondaryColor, borderColor: secondaryColor}} className="tw-p-2 tw-flex tw-flex-col tw-items-center tw-text-xl tw-justify-center tw-text-white tw-rounded-t tw-border-b ">
            <h1>{title}</h1>
            <p className="tw-text-[16px] tw-text-center">
              {subtitle}
            </p>
          </div>
    
          <div style={{color: secondaryColor, borderColor: secondaryColor}} className="tw-flex tw-text-[16px] tw-text-center tw-border-b-2 ">
            <div className="tw-font-[bold] tw-py-2 tw-w-1/3 tw-p-1">Card Type</div>
            <div style={{borderColor: secondaryColor}} className="tw-font-[bold] tw-py-2 tw-border-x-2  tw-w-1/3 tw-p-1">
              PPT (£)
            </div>
            <div className="tw-font-[bold] tw-py-2 tw-w-1/3 tw-p-1">Rate (%)</div>
          </div>
    
          {fieldList.map((item, index) => (
            <div key={index}>
            <FieldRow 
            title={item.title}
            bg={item.bg}
            value={ratesAndProducts[ratesKey][item.key]}
            secondaryColor={secondaryColor}
            />
              </div>
          ))}
    
          <div className="tw-flex tw-rounded-b-lg">
            <div className="tw-font-[semibold] tw-px-2 tw-w-1/3 tw-p-1">
              Uncategorized
            </div>
            <div style={{borderColor: secondaryColor}} className="tw-w-1/3 tw-font-[semibold] tw-p-1 tw-text-center tw-border-x-2 ">
              {ratesAndProducts[ratesKey]?.uncategorized?.ppt || "0"}
            </div>
            <div className="tw-w-1/3 tw-font-[semibold] tw-text-center tw-p-1">
              {ratesAndProducts[ratesKey]?.uncategorized?.rate || "0"}
            </div>
          </div>
        </div>
      );
}

export default ProposalFeilds