import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../index";
import applicationServiceAxios from "../../utils/axios2.js";
import appAxios from "../../utils/axios";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  countries: [],
  nationalities: [],
  username: '',
};

const slice = createSlice({
  name: "extra",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setCountries(state, action) {
      state.countries = action.payload;
    },

    setNationalities(state, action) {
      state.nationalities = action.payload;
    },

    setAgents(state, action) {
      state.agents = action.payload
    },

    setPartners(state, action) {
      state.partners = action.payload
    },

    setUser(state, action) {
      state.user = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { hasError, startLoading, setUser } = slice.actions;

export function getCountries() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await applicationServiceAxios.get("/country");
      dispatch(slice.actions.setCountries(response.data));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNationalities() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await applicationServiceAxios.get("/nationality");
      dispatch(slice.actions.setNationalities(response.data));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgents() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await appAxios.get("v1/agents");
      dispatch(slice.actions.setAgents(response.data));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPartners() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await appAxios.get("v1/partners");
      dispatch(slice.actions.setPartners(response.data));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
