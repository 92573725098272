import PropTypes from "prop-types";
// @mui
import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyle";
import jwtDecode from "jwt-decode";
import { isValidToken, setSession } from "../../../utils/jwt";
// config
import { HEADER, NAVBAR } from "../../../config";
// components
import { IconButtonAnimate } from "../../../components/animate";
import Iconify from "../../../components/Iconify";
import AccountPopover from "./AccountPopover";
import { useEffect} from "react";
import logo from "../../../assets/images/plainLogo.png";
import { dispatch, useSelector } from "../../../redux";
import { setUser} from "../../../redux/slices/extra";

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isCollapse" && prop !== "isOffset",
})(({ isCollapse, isOffset, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  backgroundColor: "white",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
};


export default function DashboardHeader({ onOpenSidebar, isCollapse = false }) {

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      setSession(accessToken);
      const decoded = jwtDecode(accessToken);
      dispatch(setUser({username: decoded.name.charAt(0).toUpperCase() + decoded.name.slice(1), userId: decoded.id}))
    }
  }, []);

  const {user} = useSelector((state) => state.extra)
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);
  const isDesktop = useResponsive("up", "lg");

  return (
    <RootStyle
      className="tw-py-4 tw-h-20 tw-shadow-lg"
      isCollapse={isCollapse}
      isOffset={isOffset}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {isCollapse && (
          <img src={logo} alt='' className="tw-hidden sm:tw-block tw-h-full" />
        )}

        <Box sx={{ flexGrow: 1 }} />
        <div className="tw-w-64 tw-bg-swipenGrey-100 tw-py-1 tw-px-4 tw-flex tw-items-center tw-justify-end tw-rounded-lg">
          <p className="tw-text-swipenBlue-500 tw-mr-4">
            Welcome, <span className="tw-font-['Bold']">{user?.username || 'User'}</span>
          </p>
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            <AccountPopover />
          </Stack>
        </div>
      </Toolbar>
    </RootStyle>
  );
}
