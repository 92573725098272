// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
// ----------------------------------------------------------------------
import dashboard from "../../../assets/images/Dashboard.png";
import customers from "../../../assets/images/Customers.png";
import users from "../../../assets/images/Users.png";
import partners from "../../../assets/images/Partner_white.svg";

const ICONS = {
  dashboard: <img src={dashboard} alt='' className="tw-w-8 tw-h-8" />,
  customers: <img src={customers} alt='' className="tw-w-8 tw-h-8" />,
  users: <img src={users} alt='' className="tw-w-8 tw-h-8" />,
  partners: <img src={partners} alt='' className="tw-w-8 tw-h-8" />,
};

const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.root,
        icon: ICONS.dashboard,
      },
      {
        title: "Customers",
        path: PATH_DASHBOARD.customer.root,
        icon: ICONS.customers,
      },
   

      {
        title: "Partners",
        path: PATH_DASHBOARD.partners.root,
        icon: ICONS.partners,
      },
      {
        title: "Users",
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        component: '',
      },
    
    ],
  },
];

export default navConfig;
