import React, { useEffect, useState } from "react";
import miniLogo from "../../../../assets/images/mini-logo.png";
import PayementPriceProposalForm from "./PayementPriceProposalForm";
import Logo from "../../../../assets/images/plainLogo.png";
import appAxios from "../../../../utils/axios";
import { useParams } from "react-router-dom";
import loadIcon from "../../../../assets/images/loading2.png";

const PayementPriceProposal = () => {
  let { uuid } = useParams();
  const [ratesInfo, setRatesInfo] = useState("");
  const [ratesAndProducts, setRatesAndProducts] = useState([]);
  const [applicationReference, setApplicationReference] = useState();
  const [primaryColor, setPrimaryColor] = useState("#ea5b1b");
  const [secondaryColor, setSecondaryColor] = useState("#213b87");
  const [logo, setLogo] = useState(Logo);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getClientRatesAndProducts = async () => {
      setLoading(true);
      if (uuid) {
        const response = await appAxios.post("v1/clientRates", {
          uuid: uuid,
        });
        setRatesInfo(response.data);
        setRatesAndProducts(response.data.info);
        setApplicationReference(response.data.application_reference);
        setPrimaryColor(response.data.primary_colour);
        setSecondaryColor(response.data.secondary_colour);
        setLogo(response.data.logo_path);
        setLoading(false);
      }
    };

    try {
      getClientRatesAndProducts();
    } catch {
      alert("Failed to get rates");
      setLoading(false);
    }
  }, []);

  const isSwipen =
    primaryColor === "#ea5b1b" && secondaryColor === "#213b87" && logo === "";

  if (!loading) {
    if (Array.isArray(ratesInfo)) {
      return (
        <div className=" tw-flex tw-flex-col tw-justify-center tw-space-y-8 tw-items-center tw-text-center tw-h-[92vh] tw-p-4 tw-bg-gray-50 tw-m-8 tw-rounded-xl">
          <h1 className="tw-text-4xl">Page Not Found</h1>

          <p className="tw-text-xl tw-font-[Bold]">
            We couldn't find a price proposal with this URL.
          </p>

          <p className="tw-text-xl tw-font-[Bold]">
            Please check your email for a more recent price proposal or request
            a new one from your support contact.
          </p>
        </div>
      );
    } else if (typeof ratesInfo == "object") {
      return (
        <div className="tw-bg-gray-200">
          <div className="tw-flex tw-flex-col tw-justify-center md:tw-mx-20 tw-mt-2 tw-bg-white tw-shadow-md">
            <div
              style={{ backgroundColor: primaryColor }}
              className="tw-w-full tw-h-4"
            ></div>
            <div className="tw-w-full tw-h-1 tw-bg-white"></div>
            <div
              style={{ backgroundColor: primaryColor }}
              className="tw-w-full tw-h-2"
            ></div>
            <div className="tw-w-full tw-h-1 tw-bg-white"></div>

            <div className="tw-flex tw-flex-col smlg:tw-flex-row tw-w-full lg:tw-justify-between">
              <div className="smlg:tw-w-1/2 tw-w-full tw-hidden smlg:tw-block tw-h-[196px] tw-bg-swipenGrey-600 tw-flex tw-flex-col tw-px-8 tw-py-4">
                <h1
                  style={{ color: primaryColor }}
                  className="tw-text-5xl lg:tw-text-7xl xxl:tw-text-8xl tw-font-[Bold] tw-mb-2"
                >
                  PAYMENTS
                </h1>
                <p className="tw-text-white lg:tw-text-5xl xxl:tw-text-6xl tw-text-3xl smlg:tw-text-4xl tw-font-[Bold]">
                  PRICE PROPOSAL
                </p>
              </div>
              <div className="smlg:tw-w-1/2 tw-w-full tw-flex tw-flex-col smlg:tw-flex-row tw-justify-center lg:tw-justify-start  tw-items-center smlg:tw-h-[196px]">
                <div className="tw-hidden lg:tw-block tw-w-0 tw-h-0 tw-border-[98px] tw-border-solid tw-border-transparent tw-border-l-swipenGrey-600"></div>
                <img
                  src={logo || Logo}
                  className="tw-h-auto tw-max-h-[250px] tw-object-contain smlg:tw-max-h-[100%] tw-w-10/12 lg:tw-w-6/12 tw-mr-2"
                  alt=""
                />

                <div className="smlg:tw-hidden tw-text-xl tw-mt-4">
                  <span
                    style={{ color: primaryColor }}
                    className="tw-font-[Bold]"
                  >
                    PAYMENTS
                  </span>{" "}
                  PRICE PROPOSAL
                </div>
              </div>
            </div>
            <div className="tw-w-full tw-h-1 tw-bg-white"></div>
            <div
              style={{ backgroundColor: primaryColor }}
              className="tw-w-full tw-h-2"
            ></div>
            <div className="tw-w-full tw-h-1 tw-bg-white"></div>
            {isSwipen ? (
              <>
                <div
                  style={{ backgroundColor: secondaryColor }}
                  className="tw-w-full tw-flex-col smlg:tw-flex-row tw-h-auto tw-flex tw-items-center tw-justify-around"
                >
                  <p className="tw-text-white tw-text-xl lg:tw-text-3xl xl:tw-text-4xl tw-font-[Inika-Bold] tw-py-2 lg:tw-py-4">
                    www.swipen.co.uk
                  </p>
                  <p className="tw-text-white tw-text-xl lg:tw-text-3xl xl:tw-text-4xl tw-font-[Inika-Bold] tw-py-2 lg:tw-py-4">
                    info@swipen.co.uk
                  </p>
                  <p className="tw-text-white tw-text-xl lg:tw-text-3xl xl:tw-text-4xl tw-font-[Inika-Bold] tw-py-2 lg:tw-py-4">
                    0131 202 4107
                  </p>
                </div>
                <div className="tw-w-full tw-h-1 tw-bg-white"></div>
                <div
                  style={{ backgroundColor: primaryColor }}
                  className="tw-w-full tw-h-2"
                ></div>
                <div className="tw-w-full tw-h-1 tw-bg-white"></div>
              </>
            ) : (
              <div
                style={{ backgroundColor: primaryColor }}
                className="tw-w-full tw-h-4"
              ></div>
            )}

            <PayementPriceProposalForm
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              uuid={uuid}
              setApplicationReference={setApplicationReference}
              ratesAndProducts={ratesAndProducts}
              ratesInfo={ratesInfo}
              applicationReference={applicationReference}
            />
            <div className="tw-w-full tw-mt-8 tw-my-4">
              <div
                style={{ backgroundColor: primaryColor }}
                className="tw-w-full tw-h-4"
              ></div>
              <div className="tw-w-full tw-h-1 tw-bg-white"></div>
              <div
                style={{ backgroundColor: primaryColor }}
                className="tw-w-full tw-h-2"
              ></div>
              <div className="tw-w-full tw-mb-10 tw-h-1 tw-bg-white"></div>
              <div className="tw-w-full tw-my-8 tw-flex tw-justify-center">
                <img
                  src={logo || Logo}
                  className="tw-object-fit tw-max-w-[90%] sm:tw-max-w-[40%] "
                  alt=""
                />
              </div>

              {isSwipen && (
                <div className="tw-flex tw-justify-center">
                  <div className="tw-flex tw-flex-col tw-w-[220px] sm:tw-w-auto tw-space-y-2 sm:tw-space-y-0 sm:tw-flex-row tw-my-8 tw-justify-center tw-items-start">
                    <div className="tw-flex tw-w-auto tw-justify-center tw-items-center">
                      <img
                        src={miniLogo}
                        className="tw-rotate-90 tw-w-12"
                        alt=""
                      />
                      <p className="tw-text-xl tw-font-semibold tw-text-swipenBlue-500">
                        www.swipen.co.uk
                      </p>
                    </div>
                    <div className="tw-flex tw-justify-center tw-items-center sm:tw-mx-4">
                      <img
                        src={miniLogo}
                        className="tw-rotate-90 tw-w-12"
                        alt=""
                      />
                      <p className="tw-text-xl tw-font-semibold tw-text-swipenBlue-500">
                        info@swipen.co.uk
                      </p>
                    </div>
                    <div className="tw-flex tw-justify-center tw-items-center">
                      <img
                        src={miniLogo}
                        className="tw-rotate-90 tw-w-12"
                        alt=""
                      />
                      <p className="tw-text-xl tw-font-semibold tw-text-swipenBlue-500">
                        0131 202 4107
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="tw-w-full tw-h-[100vh] tw-grid tw-place-items-center">
        <img src={loadIcon} alt="Loading" className="tw-animate-spin tw-h-16" />
      </div>
    );
  }
};

export default PayementPriceProposal;
