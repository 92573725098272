import React from "react";

const PriceProposalTerminals = ({ ratesAndProducts, secondaryColor }) => {
  const TerminalRow = ({
    model,
    quantity,
    price,
    paymentType,
    contractLength,
    extraStyles,
  }) => {
    return (
      <div
        style={{ borderColor: secondaryColor }}
        className={`tw-min-w-[700px] tw-min-h-[32px] tw-flex ${extraStyles}`}
      >
        <div className="tw-w-[30%] tw-font-[semibold] tw-p-1">{model}</div>
        <div
          style={{ borderColor: secondaryColor }}
          className="tw-w-[17.5%] tw-font-[semibold] tw-p-1 tw-border-x-2 "
        >
          {quantity}
        </div>
        <div className="tw-w-[17.5%] tw-font-[semibold] tw-p-1">
          {price ? `£${price}` : ""}
        </div>
        <div
          style={{ borderColor: secondaryColor }}
          className="tw-w-[17.5%] tw-font-[semibold] tw-p-1 tw-border-x-2 "
        >
          {paymentType?.split("_").join(" ")}
        </div>
        <div className="tw-w-[17.5%] tw-font-[semibold] tw-p-1">
          {contractLength ? contractLength !=='Ongoing' ? `${contractLength} Months` : contractLength : ""}
        </div>
      </div>
    );
  };

  return (
    <div className="tw-w-11/12 tw-animate-fadeDown scrl:tw-overflow-visible tw-overflow-hidden tw-whitespace-nowrap tw-mx-auto">
      <div
        style={{ borderColor: secondaryColor }}
        className="tw-w-full tw-overflow-x-scroll scrl:tw-overflow-x-visible tw-mt-6 tw-border-2 tw-rounded-t-lg scrl:tw-rounded-lg tw-text-center "
      >
        <div
          style={{
            backgroundColor: secondaryColor,
            borderColor: secondaryColor,
          }}
          className="tw-p-1 tw-text-white tw-text-xl tw-rounded-t tw-min-w-[700px] tw-border-b "
        >
          Card Terminals
        </div>
        <div
          style={{ color: secondaryColor, borderColor: secondaryColor }}
          className="tw-flex tw-border-b-2 tw-min-w-[700px] tw-text-[16px] "
        >
          <div className="tw-w-[30%]  tw-font-[bold] tw-p-1">
            Terminal Make and Model
          </div>
          <div
            style={{ borderColor: secondaryColor }}
            className="tw-w-[17.5%] tw-font-[bold] tw-p-1 tw-border-x-2 "
          >
            Quantity
          </div>
          <div className="tw-w-[17.5%] tw-font-[bold] tw-p-1">Price (+VAT)</div>
          <div
            style={{ borderColor: secondaryColor }}
            className="tw-w-[17.5%] tw-font-[bold] tw-p-1 tw-border-x-2 "
          >
            How to pay
          </div>
          <div className="tw-w-[17.5%] tw-font-[bold] tw-p-1">Settlement</div>
        </div>
        <TerminalRow
          model={ratesAndProducts?.cardTerminals?.T1?.model}
          quantity={ratesAndProducts?.cardTerminals?.T1?.quantity}
          price={ratesAndProducts?.cardTerminals?.T1?.price}
          paymentType={ratesAndProducts?.cardTerminals?.T1?.payment_type}
          contractLength={ratesAndProducts.cardTerminals?.T1?.contract_length}
          extraStyles={"tw-bg-blue-50 tw-border-b"}
        />
        <TerminalRow
          model={ratesAndProducts?.cardTerminals?.T2?.model}
          quantity={ratesAndProducts?.cardTerminals?.T2?.quantity}
          price={ratesAndProducts?.cardTerminals?.T2?.price}
          paymentType={ratesAndProducts?.cardTerminals?.T2?.payment_type}
          contractLength={ratesAndProducts.cardTerminals?.T2?.contract_length}
          extraStyles={"tw-border-b"}
        />
        <TerminalRow
          model={ratesAndProducts?.cardTerminals?.T3?.model}
          quantity={ratesAndProducts?.cardTerminals?.T3?.quantity}
          price={ratesAndProducts?.cardTerminals?.T3?.price}
          paymentType={ratesAndProducts?.cardTerminals?.T3?.payment_type}
          contractLength={ratesAndProducts.cardTerminals?.T3?.contract_length}
          extraStyles={"scrl:tw-rounded-b-lg tw-bg-blue-50 "}
        />
      </div>
    </div>
  );
};

export default PriceProposalTerminals;
