import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import appAxios from "../../../../utils/axios";
import PriceProposalTerminals from "./PriceProposalTerminals";
import loadIcon from "../../../../assets/images/loading2.png";
import ExtraFeeRow from "./ExtraFeeRow";
import ProposalFeilds from "./ProposalFeilds";

const PayementPriceProposalForm = ({
  uuid,
  setApplicationReference,
  ratesAndProducts,
  ratesInfo,
  applicationReference,
  primaryColor,
  secondaryColor,
}) => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const handleCheckboxChange = async () => {
    if (uuid) {
      setLoader(true);
      try {
        const res = await appAxios.post("v1/acceptPriceProposal", {
          uuid: uuid,
        });
        setApplicationReference(res.data.application_reference);
        setChecked(!checked);
        setLoader(false);
      } catch {
        alert(
          "Price proposal could not be accepted, please try again or contact support"
        );
        setLoader(false);
      }
    }
  };

  const cardPresentOptions = ["terminals", "moto-vt", "softpos"];
  const isCardPresent = cardPresentOptions.some((option) =>
    ratesAndProducts?.products?.includes(option)
  );
  const isEcom = ratesAndProducts?.products?.includes("ecom");

  const extraFields = [
    { title: "Gateway Transaction Fee", subtitle: "£0.00", key: "gateway_fee" },
    {
      title: "Software Application Fee",
      subtitle: "£0.00",
      key: "software_fee",
    },
    { title: "Authorisation Fee", subtitle: "£0.00", key: "authorization_fee" },
    { title: "Contract Length", subtitle: "Months", key: "contract_length" },
  ];

    return (
      <div>
        <div className="tw-w-full tw-flex tw-mx-auto tw-my-2 tw-flex-col">
          <div
            style={{ backgroundColor: secondaryColor }}
            className="tw-mx-auto tw-w-9/12 tw-my-4"
          >
            <h1 className="tw-py-1 tw-px-4 tw-text-white xl:tw-text-2xl tw-text-lg tw-font-semibold tw-text-center">
              Merchant Service Charges & Fees
            </h1>
          </div>

          {isCardPresent && (
            <ProposalFeilds
              title={"Card Present"}
              subtitle={
                "Fees for any transactions where the card holder is present"
              }
              ratesKey={"cardPresent"}
              secondaryColor={secondaryColor}
              ratesAndProducts={ratesAndProducts}
            />
          )}

          {isEcom && (
            <ProposalFeilds
              title={"Ecommerce"}
              subtitle={"Fees for any Online transactions"}
              ratesKey={"ecom"}
              secondaryColor={secondaryColor}
              ratesAndProducts={ratesAndProducts}
            />
          )}

          {extraFields.map((item, index) => (
            
        <div key={index}>
           <ExtraFeeRow
              title={item.title}
              subtitle={item.subtitle}
              value={ratesAndProducts[item.key]}
              secondaryColor={secondaryColor}
            />
        </div>
           
          ))}
        </div>

        {ratesAndProducts?.products?.includes("terminals") && (
          <PriceProposalTerminals
            secondaryColor={secondaryColor}
            ratesAndProducts={ratesAndProducts}
          />
        )}
       
        <div className="tw-flex tw-my-8 tw-w-auto tw-flex-col sm:tw-space-y-0 tw-space-y-8 sm:tw-flex-row tw-w-9/12 tw-mx-auto tw-justify-around tw-items-center">
          {ratesInfo.is_accepted === 0 ? (
            <>
              {checked === false && (
                <div className="sm:tw-w-5/12 tw-bg-swipenGrey-100 tw-relative tw-shadow-gray-400 tw-shadow-1 tw-p-4 tw-rounded-lg tw-w-11/12 tw-flex tw-items-center">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => handleCheckboxChange()}
                    className="tw-w-16 tw-cursor-pointer tw-h-8 tw-m-2 tw-accent-swipenOrange-500 tw-focus:tw-bg-orange-600"
                  />
                  <p className="tw-text-gray-600 tw-text-base xl:tw-text-xl tw-font-medium tw-text-wrap">
                    Click here to accept this pricing proposal
                  </p>
                  {loader && (
                    <div className="tw-w-full tw-h-full tw-absolute tw-grayscale tw-flex tw-items-center tw-justify-center">
                      <img
                        src={loadIcon}
                        alt="Loading"
                        className="tw-animate-spin tw-h-8"
                      />
                    </div>
                  )}
                </div>
              )}
              {checked === true && (
                <div className="sm:tw-w-5/12 tw-bg-swipenGrey-100 tw-shadow-gray-400 tw-justify-center tw-shadow-1 tw-p-4 tw-rounded-lg tw-w-11/12 tw-flex tw-items-center">
                  <p className="tw-text-gray-600 tw-text-base tw-text-center xl:tw-text-xl tw-font-medium tw-text-wrap">
                    Price proposal accepted, press the button to continue
                  </p>
                </div>
              )}

              {checked === false && (
                <button
                  disabled
                  style={{backgroundColor: primaryColor}}
                  className="tw-px-4 tw-h-20 xl:tw-text-xl tw-w-11/12 sm:tw-w-5/12 tw-font-medium tw-py-2 tw-opacity-[0.7] tw-ease-in tw-duration-200 tw-rounded-lg tw-text-white tw-cursor-not-allowed"
                >
                  Accept the Price Proposal before proceeding
                </button>
              )}
              {checked === true && (
                <button
                  onClick={() =>
                    navigate(`/payment/activate/${applicationReference}`)
                  }
                  style={{backgroundColor: primaryColor}}
                  className="tw-px-4 tw-h-20 tw-w-11/12 xl:tw-text-xl sm:tw-w-5/12 tw-font-medium tw-py-2  hover:tw-opacity-[0.9] tw-ease-in tw-duration-200 tw-rounded-lg tw-text-white"
                >
                  Proceed to next step of application
                </button>
              )}
            </>
          ) : (
            <>
              <div className="sm:tw-w-5/12 tw-bg-swipenGrey-100 tw-shadow-gray-400 tw-justify-center tw-shadow-1 tw-p-4 tw-rounded-lg tw-w-11/12 tw-flex tw-items-center">
                <p className="tw-text-gray-600 tw-text-base tw-text-center xl:tw-text-xl tw-font-medium tw-text-wrap">
                  Price proposal accepted, press the button to continue
                </p>
              </div>
              <button
                onClick={() =>
                  navigate(`/payment/activate/${applicationReference}`)
                }
                style={{backgroundColor: primaryColor}}
                className="tw-px-4 tw-h-20 tw-w-11/12 xl:tw-text-xl sm:tw-w-5/12 tw-font-medium tw-py-2 hover:tw-opacity-[0.9] tw-ease-in tw-duration-200 tw-rounded-lg tw-text-white"
              >
                Proceed to next step of application
              </button>
            </>
          )}
        </div>
      </div>
    );
  }

export default PayementPriceProposalForm;
