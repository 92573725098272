import React from 'react'

const ExtraFeeRow = ({title, subtitle, value, secondaryColor}) => {
  return (
    <div style={{borderColor: secondaryColor}} className="tw-w-11/12 lg:tw-text-base tw-text-xs ph:tw-text-sm tw-mt-3 tw-mx-auto tw-border-2 tw-flex tw-border-swipenBlue-500 tw-rounded-lg">
    <div className="tw-w-1/2 tw-font-[semibold] tw-px-2 tw-p-1">
      {title}{' '}
      <span className="tw-text-gray-500">({subtitle})</span>
    </div>
    <div className="tw-w-1/2 tw-p-1 tw-border-l tw-font-[semibold] tw-text-center tw-border-swipenBlue-500">
      {value || '0'}
    </div>
  </div>
  )
}

export default ExtraFeeRow