// import "./index.css";
import { useState } from "react";
import { RingSpinner, RingSpinnerOverlay } from "react-spinner-overlay";

function LoadingScreen() {
  const [overlay, setOverlay] = useState({
    circleSpinner: false,
    lineLoader: false,
    ringSpinner: false,
    dartsSpinner: false,
    ferrisWheelSpinner: false,
    rouletteSpinner: false,
    windmillSpinner: false,
    dotLoader: false,
    bounceLetterLoader: false,
  });

  const SectionContainer = ({
    setShowOverlay,
    overlayButtonColor = "#111",
    children,
  }) => {
    return (
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px 8px",
          margin: "12px",
          textAlign: "center",
          border: "1px solid rgb(212 228 228)",
          width: "100%",
          minWidth: "280px",
          minHeight: "112px",
        }}
      >
        {children}
      </section>
    );
  };

  return (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SectionContainer
          setShowOverlay={(nextShowStatus) =>
            setOverlay({ ...overlay, ringSpinner: nextShowStatus })
          }
        >
          <RingSpinner />
          <RingSpinnerOverlay
            loading={overlay.ringSpinner}
            message={
              <p style={{ marginTop: "12px" }}>
                If you want, you can insert message!
              </p>
            }
          />
        </SectionContainer>
      </div>
  );
}

export default LoadingScreen;
