import { lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import MainLayout from "../layouts/MainLayout";
// guards
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import ApplicationGuard from "../guards/ApplicationGuard";
import PayementPriceProposal from "../sections/CustomerAccountView/PriceProposal/EmailProposal/PayementPriceProposal";
import Contract from "../sections/CustomerAccountView/ApplicationPayment/Contract/Contract";
import ConsumerDiscolusure from "../sections/CustomerAccountView/ApplicationPayment/Contract/ConsumerDiscolusure";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: "auth",
      element: (
        <GuestGuard>
          <MainLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "login",
          element: <Login />,
        },
      ],
    },

    // PAYMENT Routes
    {
      path: "payment",
      element: (
        <ApplicationGuard>
          <LogoOnlyLayout />
        </ApplicationGuard>
      ),
      children: [
        {
          path: "activate/:id",
          element: <ActivatePayment />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to="/dashboard" replace />,
          index: true,
        },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/users", element: <Users /> },
        {
          path: "partners",
          children: [
            { element: <Navigate to="/partners/list" replace />, index: true },
            { path: "/partners/list", element: <Partners /> },
            { path: "/partners/account/:id", element: <PartnerAccount /> },
          ],
        },
        {
          path: "customers",
          children: [
            {
              element: <Navigate to="/customers/list" replace />,
              index: true,
            },
            { path: "/customers/list", element: <Customers /> },
            { path: "/customers/account/:id", element: <CustomerAccount /> },
          ],
        },
      ],
    },
    {
      path: "/client-rates/:uuid",
      element: <PayementPriceProposal />,
    },
    {
      path: "/client-contract/:uuid",
      element: <Contract />,
    },
    {
      path: "/consumer-disclosure",
      element: <ConsumerDiscolusure />,
    },

    // Error Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

// Payment
const ActivatePayment = Loadable(
  lazy(() => import("../pages/payment/Activate"))
);

// Dashboard
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Customers = Loadable(lazy(() => import("../pages/dashboard/customer")));
const CustomerAccount = Loadable(
  lazy(() => import("../pages/dashboard/customer/Account"))
);
const Users = Loadable(lazy(() => import("../pages/dashboard/users")));
const Partners = Loadable(lazy(() => import("../pages/dashboard/partners")));
const PartnerAccount = Loadable(
  lazy(() => import("../pages/dashboard/partners/Account"))
);

// Error
const Page500 = Loadable(lazy(() => import("../pages/error/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/error/Page404")));
