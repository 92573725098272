export const isAllFieldsFilledForOwner = (owner) => {
    const tempOwner = {...owner};
    if (tempOwner.file_upload_needed) delete tempOwner.file_upload_needed;
    if (tempOwner.ownership_percentage < 25) {
        if (tempOwner.proof_of_address) delete tempOwner.proof_of_address;
        if (tempOwner.proof_of_id) delete tempOwner.proof_of_id;
    }
    for (const value of Object.values(owner)) {
        if (tempOwner?.proof_of_address && !tempOwner?.proof_of_address?.get_url)
            return false;
        if (tempOwner?.proof_of_id && !tempOwner?.proof_of_id?.get_url)
            return false;
        if (
            tempOwner?.business_bank_statement &&
            !tempOwner?.business_bank_statement?.get_url
        )
            return false;
        if (!value) return false;
    }
    return true;
};
export const nestedNameFieldValue = (name, values) => {
    const parts = name.split(".");
    let temp: any = '';
    for (let i = 0; i < parts.length; i++) {
        if (temp === '') {
            temp = values[parts[i]] || '';
        } else {
            temp = temp[parts[i]] || '';
        }
    }
    return temp;
};

export const nestedNameFieldError = (name, errors) => {
    const length = `${name}`.split(".").length;

    let name1;
    let name2;
    let name3;

    if (length === 2) {
        name1 = `${name}`.split(".")[0];
        name2 = `${name}`.split(".")[1];

        return errors[name1] && errors[name1][name2];
    }

    if (length === 3) {
        name1 = `${name}`.split(".")[0];
        name2 = `${name}`.split(".")[1];
        name3 = `${name}`.split(".")[2];

        return errors[name1] && errors[name1][name2] && errors[name1][name2][name3];
    }

    return errors[name];
};

export const getDocumentIntailValueStructure = (for_field, type) => ({
    for_field: for_field,
    type: type,
    file_format: "",
    put_url: "",
    get_url: "",
    path: "",
});
