import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "./redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

/* Fonts */
import "./fonts/CheyenneSans-Black.ttf"
import "./fonts/CheyenneSans-BlackItalic.ttf"
import "./fonts/CheyenneSans-Bold.ttf"
import "./fonts/CheyenneSans-BoldItalic.ttf"
import "./fonts/CheyenneSans-ExtraBold.ttf"
import "./fonts/CheyenneSans-ExtraBoldItalic.ttf"
import "./fonts/CheyenneSans-ExtraLight.ttf"
import "./fonts/CheyenneSans-ExtraLightItalic.ttf"
import "./fonts/CheyenneSans-Italic.ttf"
import "./fonts/CheyenneSans-Light.ttf"
import "./fonts/CheyenneSans-LightItalic.ttf"
import "./fonts/CheyenneSans-Medium.ttf"
import "./fonts/CheyenneSans-MediumItalic.ttf"
import "./fonts/CheyenneSans-Regular.ttf"
import "./fonts/CheyenneSans-SemiBold.ttf"
import "./fonts/CheyenneSans-SemiBoldItalic.ttf"
import "./fonts/CheyenneSans-Thin.ttf"
import "./fonts/CheyenneSans-ThinItalic.ttf"
import "./fonts/Inika-Bold.ttf"
import "./fonts/Inika-Regular.ttf"



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </PersistGate>
      </ReduxProvider>
    </AuthProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
