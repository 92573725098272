import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { applicationServiceAxios } from "../../utils/axios";
import {setApplicationServiceSession} from "../../utils/jwt";

import { dispatch } from "../index";
// ----------------------------------------------------------------------


const forms = {
  business_type: {
    isUpdated: false,
  },
  business_details: {
    isUpdated: false,
  },
  business_owner_details: [],
  bank_details: {
    isUpdated: false,
  },
};

const initialState = {
  isLoading: false,
  error: null,
  showAddBusinessOwner: false,
  currentStep: "business_type",
  forms: forms,
  application_secret_token: "",
  selectedOwner: {},
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // End Loading
    endLoading(state) {
      state.isLoading = false;
    },

    // Set Show Add Business Ownser State
    setShowAddBusinessOwner(state, action) {
      state.showAddBusinessOwner = action.payload;
    },

    // Set current step
    setCurrentStep(state, action) {
      const prevTab = state.currentStep;
      const nextTab = action.payload;
      if (prevTab === "business_owner_details" && nextTab !== prevTab) {
        state.showAddBusinessOwner = false;
      }
      state.currentStep = nextTab;
    },

    // Set current step
    setSelectedOwner(state, action) {
      state.selectedOwner = action.payload;
    },

    // Set Application Secret Token
    setApplicationSecretToken(state, action) {
      state.application_secret_token = action.payload;
    },

    // Remove Application Secret Token
    removeApplicationSecretToken(state, action) {
      state.application_secret_token = action.payload;
    },

    // Set Forms Date
    setForms(state, action) {
      state.forms = action.payload;
    },

     // Set Valitor Forms
     setValitorForms(state, action) {
      state.valitorForms = action.payload;
    },

    // Set owners
    setOwners(state, action) {
      state.owners = action.payload;
    },

    // Set Form Date
    setForm(state, action) {
      state.forms = { ...forms, ...action.payload };
    },

    // Set Forms Date
    removeBusinessOwner(state, action) {
      const id = action.payload;
      const updateFormsInfo = state.forms.business_owner_details.filter(
        (owner) => owner?.id !== id
      );
      state.forms.business_owner_details = [...updateFormsInfo];
    },

    // Set Forms Date
    updateBusinessOwner(state, action) {
      const { id, email_address, newInfo } = action.payload;
      const updateFormsInfo = state.forms.business_owner_details.map((owner) =>
        owner?.id === id || owner?.email_address === email_address
          ? newInfo
          : owner
      );
      state.forms.business_owner_details = [...updateFormsInfo];
      state.selectedOwner = {};
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  setShowAddBusinessOwner,
  setCurrentStep,
  setForms,
  setForm,
  setValitorForms,
  setOwners,
  updateOwners,
  removeBusinessOwner,
  updateBusinessOwner,
  setSelectedOwner,
  setApplicationSecretToken,
  removeApplicationSecretToken,
} = slice.actions;

export function getApplication() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await applicationServiceAxios.get(
        "application/get-application",
      );
      const data = response.data;
      delete data.company_and_services;
      delete data.__v;
      dispatch(slice.actions.setForms(data));
      dispatch(slice.actions.endLoading());
    } catch (error) {
      console.log(error.message);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const updateApplication = async (allData, newData) => {
  const updateFormsInfo = { ...allData, ...newData };

  try {
    const response = await applicationServiceAxios.post(
      "application/post-application",
      updateFormsInfo,
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateApplicationOwners = async (data) => {
  const { business_owners, allData } = data;
  try {
    const newData = { ...allData, business_owner_details: business_owners };
    const response = await applicationServiceAxios.post(
      "application/post-application",
      newData
    );

    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const removeApplicationOwner = async (owners, allData) => {
  const newData = { ...allData, business_owner_details: owners };
  try {
    const response = await applicationServiceAxios.post(
      "application/post-application",
      newData
    );

    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateApplicationOwnerFile = async (data) => {
  const { file, owner, type, for_field } = data;

  try {
    const newOwner = {
      ...owner,
      file_upload_needed: {
        for_field: for_field,
        type: type,
        file_format: file.type,
        put_url: "",
        get_url: "",
        path: "",
      },
    };

    const response = await applicationServiceAxios.post(
      "file/client-contacts",
      newOwner
    );
    await axios.put(response.data.file_upload_needed.put_url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    const updatedOwner = {
      ...owner,
      [for_field]: response.data[for_field],
      file_upload_needed: response.data.file_upload_needed,
      id: response.data.id
    };
    return updatedOwner;
  } catch (error) {
    console.log(error.message);
  }
};
export const updateApplicationBankFile = async (data) => {
  const { file, bank_details } = data;

  try {
    const bank = {
      ...bank_details,
      file_upload_needed: {
        for_field: "business_bank_statement",
        type: "business_bank_statement",
        file_format: file.type,
        put_url: "",
        get_url: "",
        path: "",
      },
    };
    const response = await applicationServiceAxios.post(
      "/file/bank-files",
      bank,
    );
    await axios.put(response.data.file_upload_needed.put_url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const uploadApplicationDocumentFile = async (data) => {
  const { file, oldFile, for_field, type } = data;

  try {
    const document = {
      ...oldFile,
      file_upload_needed: {
        for_field: for_field,
        type: type,
        file_format: file.type,
        put_url: "",
        get_url: "",
        path: "",
      },
    };
    const response = await applicationServiceAxios.post(
      "/file/documents-files",
      document
    );
    await axios.put(response.data.file_upload_needed.put_url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};


