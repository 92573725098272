import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
// hooks
// components
import axios from "axios";
import LoadingScreen from "../components/LoadingScreen";
import { useDispatch } from "../redux";
import { PATH_ERROR } from "../routes/paths";
import { isValidToken, setApplicationServiceSession } from "../utils/jwt";
import applicationServiceAxios from '../utils/axios2.js'

// ----------------------------------------------------------------------

ApplicationGuard.propTypes = {
  children: PropTypes.node,
};

export default function ApplicationGuard({ children }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isTokenFound, setIsTokenFound] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { status, data } = await applicationServiceAxios.post(
          "/application/get-token",
          { application_reference: id }
        );
        if (status !== 200) {
          setIsInitialized(true);
          return;
        }
        if (data.token) setApplicationServiceSession(data.token);
        setIsTokenFound(true);
        setIsInitialized(true);
      } catch (error) {
        console.log(error.message);
        localStorage.removeItem("applicationToken");
        setIsInitialized(true);
      }
    })();
  }, [pathname, id, dispatch]);

  if (!isInitialized) return <LoadingScreen />;

  if (!isTokenFound && !isValidToken(localStorage.getItem("applicationToken")))
    return <Navigate to={PATH_ERROR.page404} />;

  return <>{children}</>;
}
