import axios from "axios";
// config
import { CRM_HOST_API, APPLICATION_SERVICE_HOST_API } from "../config";
// ----------------------------------------------------------------------

const appAxios = axios.create({
  baseURL: CRM_HOST_API,
});

appAxios.interceptors.response.use(
  (response) => {
    if(response?.data?.status === 'Token is Expired'){
      window.localStorage.removeItem("accessToken");
      window.location.reload();
    }
    return response;
  },
  (error) => {
    console.log("in the error: ", error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);


// Application Services axios connection
const applicationServiceAxios = axios.create({
  baseURL: APPLICATION_SERVICE_HOST_API,
});

applicationServiceAxios.interceptors.response.use(
  (response) => {
    if(response?.data?.status === 'Token is Expired'){
      window.localStorage.removeItem("applicationToken");
      window.location.reload();
    }
    return response;
  },
  (error) => {
    console.log("in the error: ", error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);


export  {applicationServiceAxios,appAxios};
export default appAxios;
