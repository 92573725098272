import React from 'react'

const FieldRow = ({bg, title, value, secondaryColor}) => {


  return (
    <div style={{borderColor: secondaryColor}} className={ bg ? `tw-flex tw-bg-${bg} tw-border-b tw-border-swipenBlue-500` : "tw-flex  tw-border-b tw-border-swipenBlue-500"}>
      <div className="tw-font-[semibold] tw-px-2 tw-w-1/3 tw-p-1">
        {title}
      </div>
      <div style={{borderColor: secondaryColor}} className="tw-w-1/3 tw-p-1 tw-font-[semibold] tw-text-center tw-border-x-2 tw-border-swipenBlue-500">
        {value?.ppt || '0'}
      </div>
      <div className="tw-w-1/3 tw-font-[semibold] tw-text-center tw-p-1">
       {value?.rate || '0'}
      </div>
    </div>
  )
}

export default FieldRow