import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import miniLogo from "../../../../assets/images/mini-logo.png";
import Logo from "../../../../assets/images/plainLogo.png";
import appAxios from "../../../../utils/axios";
import ContractDetails from "./ContractDetails";

const Contract = () => {
  const [contract, setContract] = useState("");
  const { uuid } = useParams();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getContract();
  }, []); // eslint-disable-line

  const getContract = async () => {
    try {
      const res = await appAxios.post("v1/viewContractForClient", {
        uuid: uuid,
      });
      if (res.status === 200) {
        setContract(res.data);
      }
    } catch {
      alert("Could not get contract, please contact support.");
    }
  };

  const handleContractSign = async (values) => {
    try {
      setLoader(true);
    const res = await appAxios.post("v1/signContract", { ...values });
    if (res.status === 200) {
      getContract();
      setLoader(false);
    } 
    } catch {
      alert('An error occurred signing the contract, please try again or contact support.')
    }
    
  };

  return (
    <div className="tw-bg-gray-200">
      <div className="tw-flex tw-flex-col tw-justify-center md:tw-mx-20 tw-mt-2 tw-bg-white tw-shadow-md">
        <div>
          <div className="tw-w-full tw-h-4 tw-bg-swipenOrange-500"></div>
          <div className="tw-w-full tw-h-1 tw-bg-white"></div>
          <div className="tw-w-full tw-h-2 tw-bg-swipenOrange-500"></div>
          <div className="tw-w-full tw-h-1 tw-bg-white"></div>

          <div className="tw-flex tw-flex-col smlg:tw-flex-row tw-w-full lg:tw-justify-between">
            <div className="smlg:tw-w-1/2 tw-w-full tw-hidden smlg:tw-flex tw-h-[196px] tw-bg-swipenGrey-600 tw-items-center tw-px-8 tw-py-4">
              <h1 className="tw-text-swipenOrange-500 tw-text-5xl lg:tw-text-7xl xxl:tw-text-8xl tw-font-[Bold] tw-mb-2">
                CONTRACT
              </h1>
            </div>
            <div className="smlg:tw-w-1/2 tw-w-full tw-flex tw-flex-col smlg:tw-flex-row tw-justify-center lg:tw-justify-start  tw-items-center tw-h-[196px]">
              <div className="tw-hidden lg:tw-block tw-w-0 tw-h-0 tw-border-[98px] tw-border-solid tw-border-transparent tw-border-l-swipenGrey-600"></div>
              <img
                src={Logo}
                className="tw-h-auto tw-w-10/12 lg:tw-w-6/12 tw-mr-2"
                alt=""
              />
              <div className="smlg:tw-hidden tw-text-xl tw-mt-4">
                <span className="tw-text-swipenOrange-500 tw-font-[Bold]">
                  CONTRACT
                </span>{" "}
              </div>
            </div>
          </div>
          <div className="tw-w-full tw-h-1 tw-bg-white"></div>
          <div className="tw-w-full tw-h-2 tw-bg-swipenOrange-500"></div>
          <div className="tw-w-full tw-h-1 tw-bg-white"></div>
          <div className="tw-w-full tw-flex-col smlg:tw-flex-row tw-h-auto tw-bg-swipenBlue-500 tw-flex tw-items-center tw-justify-around">
            <p className="tw-text-white tw-text-xl lg:tw-text-3xl xl:tw-text-4xl tw-font-[Inika-Bold] tw-py-2 lg:tw-py-4">
              www.swipen.co.uk
            </p>
            <p className="tw-text-white tw-text-xl lg:tw-text-3xl xl:tw-text-4xl tw-font-[Inika-Bold] tw-py-2 lg:tw-py-4">
              info@swipen.co.uk
            </p>
            <p className="tw-text-white tw-text-xl lg:tw-text-3xl xl:tw-text-4xl tw-font-[Inika-Bold] tw-py-2 lg:tw-py-4">
              0131 202 4107
            </p>
          </div>
        </div>
        <div className="tw-w-full tw-h-1 tw-bg-white"></div>
        <div className="tw-w-full tw-h-2 tw-bg-swipenOrange-500"></div>
        <div className="tw-w-full tw-h-1 tw-bg-white"></div>
        <ContractDetails
          uuid={uuid}
          contract={contract}
          handleContractSign={handleContractSign}
          loader={loader}
        />
        <div className="tw-w-full tw-mt-8 tw-my-4">
          <div className="tw-w-full tw-h-4 tw-bg-swipenOrange-500"></div>
          <div className="tw-w-full tw-h-1 tw-bg-white"></div>
          <div className="tw-w-full tw-h-2 tw-bg-swipenOrange-500"></div>
          <div className="tw-w-full tw-mb-10 tw-h-1 tw-bg-white"></div>
          <div className="tw-w-full tw-my-8 tw-flex tw-justify-center">
            <img src={Logo} alt="" />
          </div>
          <div className="tw-flex tw-justify-center">
            <div className="tw-flex tw-flex-col tw-w-[220px] sm:tw-w-auto tw-space-y-2 sm:tw-space-y-0 sm:tw-flex-row tw-my-8 tw-justify-center tw-items-start">
              <div className="tw-flex tw-w-auto tw-justify-center tw-items-center">
                <img src={miniLogo} className="tw-rotate-90 tw-w-12" alt="" />
                <p className="tw-text-xl tw-font-semibold tw-text-swipenBlue-500">
                  www.swipen.co.uk
                </p>
              </div>
              <div className="tw-flex tw-justify-center tw-items-center sm:tw-mx-4">
                <img src={miniLogo} className="tw-rotate-90 tw-w-12" alt="" />
                <p className="tw-text-xl tw-font-semibold tw-text-swipenBlue-500">
                  info@swipen.co.uk
                </p>
              </div>
              <div className="tw-flex tw-justify-center tw-items-center">
                <img src={miniLogo} className="tw-rotate-90 tw-w-12" alt="" />
                <p className="tw-text-xl tw-font-semibold tw-text-swipenBlue-500">
                  0131 202 4107
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contract;
