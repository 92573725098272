import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import '../index.css'

import * as React from "react";

export default function Modal({
  title,
  submitButtonTitle,
  children,
  onSubmit,
  open = false,
  setOpen,
  modalId = "exampleId",
  footer = true,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby={modalId}
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle className="tw-bg-swipenBlue-500" id={modalId}>
        <div className="tw-flex tw-items-center tw-text-white tw-justify-between">
          <h1>{title}</h1>
          <button onClick={handleClose}>
            <CloseIcon className="hover:tw-bg-red" />
          </button>
        </div>
      </DialogTitle>
      <DialogContent dividers={true}>{children}</DialogContent>
      {footer ? (
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onSubmit();
              handleClose();
            }}
          >
            {submitButtonTitle}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
