import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import extraReducer from "./slices/extra";
import paymentReducer from "./slices/payment";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const extraPersist = {
  key: "extra",
  storage,
  keyPrefix: "redux-",
  whitelist: ["countries", "nationalities"],
};

const rootReducer = combineReducers({
  extra: persistReducer(extraPersist, extraReducer),
  payment: paymentReducer,
});

export { rootPersistConfig, rootReducer };
