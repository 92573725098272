import { createContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../components/Modal";
// utils
import axios, { appAxios } from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function calculateTokenExpiry(token) {
  const payload = parseJwt(token);
  const currentTime = Math.floor(Date.now() / 1000);

  const expiresIn = payload.exp - currentTime;
  return expiresIn;
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    let popupTimer;
    let refreshTimer;

    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const expiresIn = calculateTokenExpiry(accessToken);

          if (expiresIn > 0) {
            popupTimer = setTimeout(() => {
              setShowPopup(true);
            }, (expiresIn - 60) * 1000);

            refreshTimer = setTimeout(() => {
              window.location.reload();
            }, expiresIn * 1000);

            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();

    return () => {
      clearTimeout(popupTimer);
      clearTimeout(refreshTimer);
    };
  }, []);

  const login = async (email, password) => {
    const response = await axios.post("/login", {
      email,
      password,
    });
    const { token, user } = response.data;
    setSession(token);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, name) => {
    const response = await axios.post("/register", {
      email,
      password,
      name,
    });
    const { token, user } = response.data;

    window.localStorage.setItem("accessToken", token);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const handleTokenRefresh = async (token) => {
    try {
      const res = await appAxios.post("refresh-token", token);
      setSession(res.data.token);
      setShowPopup(false);
    } catch {
      console.log(`Failed to refresh the access_token`);
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
      }}
    >
      {children}
      <Modal
        open={showPopup}
        setOpen={setShowPopup}
        footer={false}
        title={"Session Expiry"}
      >
        <div className="tw-w-64 lg:tw-w-96 tw-bg-white sm:tw-rounded-lg tw-text-center">
          <p className="tw-text-lg tw-mt-4 tw-mb-8 tw-text-swipenGrey-600 tw-font-[bold]">
            Your session is about to expire do you want to stay logged in?
          </p>

          <div className="tw-flex tw-justify-center tw-my-4">
            <button
              onClick={() =>
                handleTokenRefresh(window.localStorage.getItem("accessToken"))
              }
              className="tw-font-semibold tw-uppercase tw-leading-tight tw-h-9 tw-bg-swipenOrange-500 hover:tw-bg-swipenOrange-700 tw-rounded-lg tw-text-sm tw-text-white tw-py-1.5 tw-px-8 tw-duration-200"
            >
              Stay Logged In
            </button>
          </div>
        </div>
      </Modal>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
