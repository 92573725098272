import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormikField from "../Valitor/FormikField";
import * as Yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import paper from "../../../../assets/images/lined-paper";
import loadIcon from "../../../../assets/images/loading2.png";

const ContractDetails = ({ uuid, contract, handleContractSign, loader }) => {
  const url = contract?.uri;
  const [dateTime, setDateTime] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().toLocaleString();
      setDateTime(now);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const initialValues = {
    signature: "",
    accepted: false,
    ip: ipAddress,
    uuid: uuid,
    clients_id: contract.clients_id,
  };

  const validationSchema = Yup.object().shape({
    signature: Yup.string().required("Required"),
  });

  if (url) {
    return (
      <div className="tw-mt-8">
        <div className="tw-w-full tw-flex tw-justify-center">
          <iframe
            title="contract"
            src={url}
            width="810px"
            height="1200px"
          ></iframe>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => handleContractSign(values)}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            isSubmitting,
            errors,
            values,
          }) => {
            return (
              <Form action="#" method="POST">
                <div className="tw-border tw-relative tw-border-gray-700 tw-rounded-lg tw-p-4 tw-mt-8 tw-mx-auto tw-max-w-[1200px]">
                  {contract.is_signed === 0 ? (
                    <>
                      <div>
                        <div className="tw-text-end tw-text-swipenBlue-500">
                          {dateTime}
                        </div>
                        <div className="tw-text-end tw-text-swipenBlue-500">
                          {" "}
                          IP: {ipAddress}
                        </div>
                        <p className="tw-text-center tw-text-lg tw-text-swipenBlue-500 tw-font-[semibold]">
                          Type the name of the individual signing the contract
                        </p>

                        <FormikField name="signature" />
                        <p className="tw-text-center tw-text-lg tw-text-swipenBlue-500 tw-font-[semibold]">
                          Signature preview
                        </p>
                        <div className="tw-h-40 tw-overflow-hidden tw-relative">
                          <img
                            className="tw-absolute tw-top-[-40px] tw-w-[1166px] tw-h-[1154.66px]"
                            src={paper}
                            alt=""
                          />
                          <div className="tw-absolute tw-text-center tw-w-full tw-z-5 tw-font-[signature] tw-text-6xl tw-top-[40px]">
                            {values.signature}
                          </div>
                        </div>
                      </div>
                      {loader && (
                        <div className="tw-w-full tw-h-full tw-top-0 tw-absolute tw-flex tw-items-center tw-justify-center">
                          <img
                            src={loadIcon}
                            alt="loading"
                            className="tw-animate-spin tw-h-16"
                          />
                        </div>
                      )}
                      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                        <div className="tw-flex tw-w-full tw-justify-center tw-items-center">
                          <p className="tw-text-center tw-text-swipenBlue-500 tw-font-[semibold]">
                            I have read and understood the contents contained
                            within this agreement.
                          </p>
                          <Field
                            type="checkbox"
                            name="accepted"
                            onChange={handleChange}
                            className="tw-accent-swipenOrange-500 tw-cursor-pointer tw-min-w-[20px] tw-min-h-[20px] tw-mx-4"
                          />
                        </div>
                        <p className="tw-text-center tw-text-swipenBlue-500 tw-font-[semibold] tw-mt-2">
                          By signing, I agree to this agreement, the{" "}
                          <a
                            className="tw-underline"
                            href="/consumer-disclosure"
                            target="_blank"
                          >
                            Consumer Disclosure
                          </a>{" "}
                          and to do business electronically with Swipen Ltd.
                        </p>
                        {values.accepted === true ? (
                          <button
                            type="submit"
                            className="tw-mt-6 tw-bg-swipenOrange-500 hover:tw-bg-swipenOrange-700 tw-px-4 tw-py-2 tw-text-white tw-font-bold tw-rounded-lg tw-uppercase tw-tracking-widest"
                            onClick={handleSubmit}
                          >
                            Sign
                          </button>
                        ) : (
                          <button
                            type="submit"
                            disabled
                            className="tw-mt-6 tw-bg-gray-300 tw-cursor-not-allowed tw-px-4 tw-py-2 tw-text-white tw-font-bold tw-rounded-lg tw-uppercase tw-tracking-widest"
                          >
                            Sign
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="tw-text-center">
                      <h1 className="tw-text-xl tw-text-swipenBlue-500 tw-flex tw-items-center tw-justify-center">
                        <CheckIcon className="tw-text-white tw-bg-swipenBlue-500 tw-rounded-full tw-p-1 tw-mx-2 tw-text-4xl" />
                        You're all set
                      </h1>
                      <p className="tw-text-lg tw-mt-4">
                        You finished signing 'Valitor Tripartite Agreement 2023'
                      </p>
                      <p className="tw-text-lg tw-mt-2">
                        Click{" "}
                        <a
                          href={contract.uri}
                          target="_blank"
                          rel="noreferrer"
                          className="tw-cursor-pointer tw-text-swipenBlue-500"
                        >
                          here
                        </a>{" "}
                        to view and download a copy of what you just signed.
                      </p>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  } else {
    return (
      <div className="tw-mt-8 tw-mb-4 tw-text-center tw-text-4xl tw-text-swipenGrey-600 tw-font-[bold]">
        No Contract to display, please try the link again. If the problem
        persists contact support.
      </div>
    );
  }
};

export default ContractDetails;
